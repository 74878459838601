import React from 'react';

const GradientLegend = ({
    hazard,
    startColor,
    endColor,
    startLabel,
    endLabel
   }) => {
  const gradientStyle = {
    background: `linear-gradient(to right, ${startColor}, ${endColor})`,
    width: '90%',
    height: '20px',
    borderRadius: '5px',
    border: '0px solid #fff',
    marginBottom: '10px'
  };

  const labelStyle = {
    fontSize: '16px',
    fontWeight: 'bold'
  };

  if (hazard && hazard !== "SLR" && endLabel <= 1) {
    endLabel = (endLabel.toFixed(4) * 100).toFixed(2) + '%';
  } else if (hazard === "SLR") {
    endLabel = 1
  }

  return (
    <div>
      <div style={{ ...gradientStyle, margin: '0 5%' }}></div>
      <div style={{ display: 'flex', justifyContent: 'space-between', margin: '0 5%' }}>
        <span style={labelStyle}>{startLabel}</span>
        <span style={labelStyle}>{endLabel}</span>
      </div>
    </div>
  );
};

export default GradientLegend;