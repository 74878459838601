import React from "react";
import "./ComposeMapLegend.css";
import "../Layout/DropdownMenu.css";
import GradientLegend from "../Layout/GradientLegend";
import {
  wildfireStyle,
} from "../Layout/ComposeLayerStyle";

const ComposeMapLegend = (props) => {
  const {
    adminStyling,
    selectedLayer,
    assetPointsVisible,
    setAssetPointsVisible,
    assetPointsWeightVisible,
    setAssetPointsWeightVisible,
    layerVisible,
    isMainMap,
    types,
  } = props;

  let polyColorStyle = selectedLayer ? adminStyling[selectedLayer.propertyName] : wildfireStyle;
  let gradientStartColor = polyColorStyle.paint["fill-color"][6];
  let gradientEndColor = polyColorStyle.paint["fill-color"][8];
  let items = types.slice(0, 15);
  let unit = selectedLayer ? selectedLayer.unit : "%";

  return (
    <>
      {isMainMap && (
        <div className="map-legend">
          <h3 className="ssp-title">Asset Types</h3>
          {items.map((item) => (
            <div key={item.name} className="legend-item">
              <span
                className="legend-color"
                style={{ backgroundColor: item.color }}
              ></span>
              <span className="legend-text">{item.name}</span>
            </div>
          ))}
          <div className="asset-points-visibility">
            <input
              type="checkbox"
              checked={assetPointsVisible}
              onChange={(e) => setAssetPointsVisible(e.target.checked)}
              className="asset-points-visibility-checkbox"
            />
            <label className="asset-points-visibility-label">
              Show Asset Points
            </label>
          </div>

          {selectedLayer && layerVisible && isMainMap && (
            <>
              <h6 className="ssp-title">{selectedLayer.displayName} ({unit})</h6>
              <GradientLegend
                startColor={gradientStartColor}
                endColor={gradientEndColor}
                startLabel={selectedLayer.displayMin}
                endLabel={selectedLayer.displayMax}
              />
            </>
          )}

        </div>
      )}
    </>
  );
};

export default ComposeMapLegend;
