import React, { useState, useEffect, useRef } from "react";
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import "./SubMap.css"
import "./ComposeMapLegend.css"
import {
  outlineStyle,
  wildfireStyle,
} from "../Layout/ComposeLayerStyle";

import GradientLegendSmall from "../Layout/GradientLegendSmall";

const SubMap = (props) => {
  const {
    parentViewState,
    selectedLayer,
    sourceId,
    composePolys,
    adminLevel,
    adminStyling,
  } = props;

  const accessToken = process.env.REACT_APP_ESRI_TOKEN; 
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [sources, setSources] = useState({});

  useEffect(() => {
    if (map.current) return; // stops map from intializing more than once

    map.current = new maplibregl.Map({
      container: mapContainer.current,
      style: `https://basemapstyles-api.arcgis.com/arcgis/rest/services/styles/v2/styles/arcgis/dark-gray?token=${accessToken}`,
      center: [parentViewState.longitude, parentViewState.latitude],
      zoom: parentViewState.zoom-1.5,
    });
  }, [accessToken]);

  useEffect(() => {
    map.current.setZoom(parentViewState.zoom-1.5)
    map.current.setCenter([parentViewState.longitude, parentViewState.latitude])
  }, [parentViewState]);
  
  useEffect(() => {
    map.current.on('load', () => {
      let sources = {"2": [],  "3": []};
      composePolys.forEach((level) => {
        level.forEach((poly) => {
          var data = require(`../../data/geojson/compose_polys/admin${poly.level}/${poly.name}`);
          let srcName = `${sourceId}-${poly.level}-${poly.name}`;
          map.current.addSource(srcName, {
            type: 'geojson',
            data: data,
          });
          sources[`${poly.level}`].push(srcName);
        });
      });
      setSources(sources);
    });
  }, [composePolys]);

  useEffect(() => {
    if (selectedLayer) {
      let layers = map.current.getStyle().layers.filter(i => i.source ? i.source.includes(sourceId) : false);
      layers.forEach((layer) => {
        map.current.removeLayer(layer.id);
      });

      sources[`${adminLevel}`].forEach((src) => {
        const hazardLabel = selectedLayer.propertyName;
        const min = selectedLayer.min;
        const max = selectedLayer.max;
  
        let newPolygonStyle = {};
        newPolygonStyle = JSON.parse(JSON.stringify(adminStyling[selectedLayer.propertyName]));
        newPolygonStyle.id = `${src}-${hazardLabel}-layer`;
        newPolygonStyle.paint["fill-color"][2][1] = hazardLabel;
        newPolygonStyle.paint["fill-color"][5] = min;     
        newPolygonStyle.paint["fill-color"][7] = max;
        newPolygonStyle.source = `${src}`;

        let newOutlineStyle = {};
        newOutlineStyle = JSON.parse(JSON.stringify(outlineStyle));
        newOutlineStyle.id = `${src}-${hazardLabel}-outline-layer`;
        newOutlineStyle.paint["line-width"] = .15;
        newOutlineStyle.source = `${src}`;
  
        map.current.addLayer(newPolygonStyle);
        map.current.addLayer(newOutlineStyle);
        
        map.current.on('click', `${src}-${hazardLabel}-layer`, (e) => {
          let val = e.features[0].properties[selectedLayer.propertyName];
          let descriptor = "";
          if ('zip' in e.features[0].properties) {
            descriptor = e.features[0].properties['zip'];
          } else if ('county' in e.features[0].properties) {
            descriptor = `${e.features[0].properties['county']}, ${e.features[0].properties['state']}`;
          }

          let description = descriptor === "" ? "" : `<strong>${descriptor}</strong><br>`;
          if (selectedLayer.unit === "%" && val < 1) {
            val = val * 100;
            description += `${val === 0 ? val : val.toFixed(4)}${selectedLayer.unit}`;
          } else if (selectedLayer.unit === "%" && val >= 1) {
            description += `${val.toFixed(1)}${selectedLayer.unit}`;
          } else if (selectedLayer.unit === "$") {
            description += `${selectedLayer.unit}${val.toFixed(2)}`;
          } else {
            description += `${selectedLayer.unit} ${val}`;
          }

          if (newPolygonStyle.id.includes(selectedLayer.propertyName)) {
            const popup = new maplibregl.Popup({
              closeButton: false,
              closeOnClick: true
            });
            popup.setLngLat([e.lngLat.lng, e.lngLat.lat]).setHTML(description).addTo(map.current);
          }
        });
      });
    }
  }, [selectedLayer, adminLevel]);

  let polyColorStyle = selectedLayer ? adminStyling[selectedLayer.propertyName] : wildfireStyle;
  let gradientStartColor = polyColorStyle.paint["fill-color"][6];
  let gradientEndColor = polyColorStyle.paint["fill-color"][8];
  
  return (
    <div ref={mapContainer} className="submap">
      {selectedLayer && (
      <div className="map-legend-small">
        {/* <span className="ssp-title-small">{selectedLayer.displayName} ({unit})</span> */}
        <span className="ssp-title-small"></span>
        <GradientLegendSmall
          startColor={gradientStartColor}
          endColor={gradientEndColor}
          startLabel={selectedLayer.displayMin}
          endLabel={selectedLayer.displayMax}
        />
        </div>
      )}
    </div>
  );
};

export default SubMap;