
// Controls color and size of asset points at different zoom levels. ONLY NEED TO CHANGE THESE VARIABLES TO CHANGE POINT STYLE
const z5PointRadiusMin = 2.5;
const z5PointRadiusMax = 25;

const z10PointRadiusMin = 7;
const z10PointRadiusMax = 105;

const polyOutlineMin = .1;
const polyOutlineMax = 1.5;

// Controls color and size of asset points
const assetTypeStyle = {
  id: "point-layer",
  type: "circle",
  paint: {
      'circle-radius':  [
        'interpolate',
        ['linear'],
        ['zoom'],
        5, [ // Size at zoom level 5
          'interpolate',
          ['linear'], // Interoplate circle size linearly
          ['get', 'financialExposureWeight'],
          0, z5PointRadiusMin, // If financialExposureWeight is 0, circle radius is 2
          0.05, z5PointRadiusMax  // If financialExposureWeight is 0.01, circle radius is 25
        ],
        10, [ // Size at zoom level 10
          'interpolate',
          ['linear'], // Interoplate circle size linearly
          ['get', 'financialExposureWeight'],
          0, z10PointRadiusMin, // If financialExposureWeight is 0, circle radius is 5
          0.05, z10PointRadiusMax // If financialExposureWeight is 0.01, circle radius is 105
        ]
      ],
      'circle-color': [
        'match',
        ['get', 'type'], // Get the 'type' property from each feature
        'Default', '#ccc', // Quiet compiler warnings
        '#99e6ff' // Default color if none of the above types match
      ]
    }
};

const hazardLabelStyle = {
  id: "point-layer",
  type: "circle",
  paint: {
    'circle-radius': [
      'interpolate',
      ['linear'],
      ['zoom'],
      5, [
        'interpolate',
        ['linear'],
        ['get', 'financialExposureWeight'],
        0, z5PointRadiusMin, 
        0.05, z5PointRadiusMax 
      ],
      10, [
        'interpolate',
        ['linear'],
        ['get', 'financialExposureWeight'],
        0, z10PointRadiusMin,
        0.05, z10PointRadiusMax
      ]
    ],
    'circle-color': [
      'match',
      ['get', 'hazardSummaryLabel'], 
      'LOW', '#89DA59',
      'MEDIUM', '#FBB13C',
      'HIGH', '#ff0000',
      '#ccc' 
    ]
  }
};

// Controls color and size of asset points
const assetTypeStyleNoWeights = {
  id: "point-layer",
  type: "circle",
  paint: {
      'circle-radius':  [
        'interpolate',
        ['linear'],
        ['zoom'],
        5, z5PointRadiusMin,
        10, z10PointRadiusMin
      ],
      'circle-color': [
        'match',
        ['get', 'type'], // Get the 'type' property from each feature
        'Default', '#999999', // Quiet compiler warnings
        '#99e6ff' // Default color if none of the above types match
      ]
    }
};

const hazardLabelStyleNoWeights = {
  id: "point-layer",
  type: "circle",
  paint: {
    'circle-radius': [
      'interpolate',
      ['linear'],
      ['zoom'],
      5, z5PointRadiusMin,
      10, z10PointRadiusMin
    ],
    'circle-color': [
      'match',
      ['get', 'hazardSummaryLabel'], 
      'LOW', '#89DA59',
      'MEDIUM', '#FBB13C',
      'HIGH', '#ff0000',
      '#ccc' 
    ]
  }
};

const pointBorder = {
  id: 'border-layer',
  type: 'circle',
  paint: {
    'circle-color': '#FFFFFF',
    'circle-radius':  [
      'interpolate',
      ['linear'],
      ['zoom'],
      5, [
        'interpolate',
        ['linear'],
        ['get', 'financialExposureWeight'],
        0, z5PointRadiusMin + 1, 
        0.05, z5PointRadiusMax + 1 
      ],
      10, [
        'interpolate',
        ['linear'],
        ['get', 'financialExposureWeight'],
        0, z10PointRadiusMin + 1,
        0.05, z10PointRadiusMax + 1
      ]
    ],
  }
};

const pointBorderNoWeights = {
  id: 'border-layer',
  type: 'circle',
  paint: {
    'circle-color': '#000000',
    'circle-radius':  [
      'interpolate',
      ['linear'],
      ['zoom'],
      5, z5PointRadiusMin + 1,
      10, z10PointRadiusMin + 1
    ],
  }
};

// Line style for outlines
const outlineStyle = {
  id: "polygon-line-layer",
  type: "line",
  paint: {
    "line-color": [
      'interpolate',
      ['linear'],
      ['zoom'],
      1.5, "rgba(99,102,106,.5)",
      10, "#FFFFFF"
    ],
    "line-width": [
      'interpolate',
      ['linear'],
      ['zoom'],
      1.5, polyOutlineMin,
      10, polyOutlineMax
    ],
  }
};

// Polygon style for wildfire
const wildfireStyle = {
  id: "polygon-layer",
  type: "fill",
  paint: {
      'fill-color': [
        'interpolate',
        ['linear'],
        ['get', 'placeholder'],
        0, 'rgba(255, 255, 255, 0.1)',
        0.0001, 'rgba(255, 0, 180, 0.1)', // Lower limit value and color
        0.99, 'rgba(255, 0, 180, 1)' // Upper limit value and color
      ],
      'fill-outline-color': 'rgba(242, 229, 229, 0.1)'
    }
};

// Polygon style for wildfire
const wildfireStyleBinary = {
  id: "polygon-layer",
  type: "fill",
  paint: {
      'fill-color': [
        'interpolate',
        ['linear'],
        ['get', 'placeholder'],
        -1, 'rgba(255, 0, 180, 0.01)', // Lower limit value and color
        0, 'rgba(255, 0, 180, 0.1)', // Lower limit value and color
        0.99, 'rgba(255, 0, 180, 1)' // Upper limit value and color
      ],
      'fill-outline-color': 'rgba(242, 229, 229, 0.1)'
    }
};

// Polygon style for flood
const floodStyle = {
  id: "polygon-layer",
  type: "fill",
  paint: {
      'fill-color': [
        'interpolate',
        ['linear'],
        ['get', 'placeholder'],
        0, 'rgba(255, 255, 255, 0.1)',
        0.0001, 'rgba(0, 255, 255, 0.1)',
        0.99, 'rgba(0, 255, 255, 1)'
      ],
      'fill-outline-color': 'rgba(242, 229, 229, 0.1)'
    }
};

// Polygon style for flood
const floodStyleBinary = {
  id: "polygon-layer",
  type: "fill",
  paint: {
      'fill-color': [
        'interpolate',
        ['linear'],
        ['get', 'placeholder'],
        -1, 'rgba(255, 255, 255, 0.1)',
        0, 'rgba(0, 255, 255, 0.1)',
        0.99, 'rgba(0, 255, 255, 0.5)'
      ],
      'fill-outline-color': 'rgba(242, 229, 229, 0.1)'
    }
};

// Polygon style for cyclone
const cycloneStyle = {
  id: "polygon-layer",
  type: "fill",
  paint: {
      'fill-color': [
        'interpolate',
        ['linear'],
        ['get', 'placeholder'],
        0, 'rgba(255, 255, 255, 0.1)',
        0.0001, 'rgba(0, 255, 255, 0.1)',
        0.99, 'rgba(0, 255, 255, 1)'
      ],
      'fill-outline-color': 'rgba(242, 229, 229, 0.1)'
    }
};

// Polygon style for cyclone
const cycloneStyleBinary = {
  id: "polygon-layer",
  type: "fill",
  paint: {
      'fill-color': [
        'interpolate',
        ['linear'],
        ['get', 'placeholder'],
        -1, 'rgba(255, 255, 255, 0.1)',
        0, 'rgba(0, 255, 255, 0.1)',
        0.99, 'rgba(0, 255, 255, 1)'
      ],
      'fill-outline-color': 'rgba(242, 229, 229, 0.1)'
    }
};

// Polygon style for financial data
const financialStyle = {
  id: "polygon-layer",
  type: "fill",
  paint: {
      'fill-color': [
        'interpolate',
        ['linear'],
        ['get', 'placeholder'],
        0, 'rgba(255, 255, 255, 0.1)',
        0.0001, 'rgba(8, 195, 24, 0.1)',
        0.99, 'rgba(8, 195, 24, 1)'
      ],
      'fill-outline-color': 'rgba(242, 229, 229, 0.1)'
    }
};

// Polygon style for financial data
const financialStyleHigh = {
  id: "polygon-layer",
  type: "fill",
  paint: {
      'fill-color': [
        'interpolate',
        ['linear'],
        ['get', 'placeholder'],
        0, 'rgba(255, 255, 255, 0.1)',
        1500, 'rgba(8, 195, 24, 0.1)',
        0.99, 'rgba(8, 195, 24, 1)'
      ],
      'fill-outline-color': 'rgba(242, 229, 229, 0.1)'
    }
};

// Polygon style for financial data
const householdStyle = {
  id: "polygon-layer",
  type: "fill",
  paint: {
      'fill-color': [
        'interpolate',
        ['linear'],
        ['get', 'placeholder'],
        0, 'rgba(255, 255, 255, 0.1)',
        37800, 'rgba(193, 73, 255, 0.1)',
        0.99, 'rgba(193, 73, 255, 1)'
      ],
      'fill-outline-color': 'rgba(242, 229, 229, 0.1)'
    }
};

// Polygon style for financial data
const demoStyle = {
  id: "polygon-layer",
  type: "fill",
  paint: {
      'fill-color': [
        'interpolate',
        ['linear'],
        ['get', 'placeholder'],
        0, 'rgba(255, 255, 255, 0.1)',
        0.1, 'rgba(193, 73, 255, 0.1)',
        0.99, 'rgba(193, 73, 255, 1)'
      ],
      'fill-outline-color': 'rgba(242, 229, 229, 0.1)'
    }
};

// Polygon style for financial data
const resilienceStyle = {
  id: "polygon-layer",
  type: "fill",
  paint: {
      'fill-color': [
        'interpolate',
        ['linear'],
        ['get', 'placeholder'],
        0, 'rgba(255, 255, 255, 0.1)',
        0.4, 'rgba(0, 80, 255, 0.01)',
        0.99, 'rgba(0, 80, 255, 1)'
      ],
      'fill-outline-color': 'rgba(242, 229, 229, 0.1)'
    }
};

// Polygon style for resilience data
const resilienceTotalStyle = {
  id: "polygon-layer",
  type: "fill",
  paint: {
      'fill-color': [
        'interpolate',
        ['linear'],
        ['get', 'placeholder'],
        0, 'rgba(255, 255, 255, 0.1)',
        2.5, 'rgba(0, 80, 181, 0.01)',
        0.99, 'rgba(0, 80,  181, 1)'
      ],
      'fill-outline-color': 'rgba(242, 229, 229, 0.1)'
    }
};

// Polygon style for insurance
const insuranceStyleRed = {
  id: "polygon-layer",
  type: "fill",
  paint: {
      'fill-color': [
        'interpolate',
        ['linear'],
        ['get', 'placeholder'],
        0, 'rgba(255, 255, 255, 0.1)',
        0.005, 'rgba(255, 0, 0, 0.1)', // Lower limit value and color
        0.99, 'rgba(255, 0, 0, 1)' // Upper limit value and color
      ],
      'fill-outline-color': 'rgba(242, 229, 229, 0.1)'
    }
};

// Polygon style for insurance
const insuranceStyleOrange = {
  id: "polygon-layer",
  type: "fill",
  paint: {
      'fill-color': [
        'interpolate',
        ['linear'],
        ['get', 'placeholder'],
        0, 'rgba(255, 255, 255, 0.1)',
        2, 'rgba(255, 100, 0, 0.1)', // Lower limit value and color
        0.99, 'rgba(255, 100, 0, 1)' // Upper limit value and color
      ],
      'fill-outline-color': 'rgba(242, 229, 229, 0.1)'
    }
};

// Polygon style for modeled data
const modeledStyle = {
  id: "polygon-layer",
  type: "fill",
  paint: {
      'fill-color': [
        'interpolate',
        ['linear'],
        ['get', 'placeholder'],
        0, 'rgba(255, 255, 255, 0.1)',
        0.01, 'rgba(255, 255, 0, 0.1)',
        0.05, 'rgba(255, 255, 0, 1)'
      ],
      'fill-outline-color': 'rgba(242, 229, 229, 0.1)'
    }
};

export { 
  assetTypeStyle,
  assetTypeStyleNoWeights,
  hazardLabelStyle,
  hazardLabelStyleNoWeights,
  pointBorder,
  pointBorderNoWeights,
  outlineStyle,
  wildfireStyle,
  wildfireStyleBinary,
  cycloneStyle,
  cycloneStyleBinary,
  floodStyle,
  floodStyleBinary,
  financialStyle,
  financialStyleHigh,
  resilienceStyle,
  resilienceTotalStyle,
  modeledStyle,
  householdStyle,
  demoStyle,
  insuranceStyleRed,
  insuranceStyleOrange
};