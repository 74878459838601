import React, { useState, useContext } from "react";
import "./ComposeLayerSelector.css";
import DropdownMenu from "./DropdownMenu";
import {authContext} from "../../context/authContext";
import {ce_default_url} from "../../context/firebase-config";

const ComposeLayerSelector = (props) => {
  const {
    selectedProject,
    selectedPortfolio,
    setSelectedPortfolio,
    portfolios,
    setSelectedBoundary,
    setFunctionLayer,
    functionOptions,
    composeMainMap,
  } = props;

  const [layerFunctionName, setLayerFunctionName] = useState(null);

  const user = useContext(authContext);
  const downloadFile = (user, selectedProject, selectedPortfolio) => {
    user && user.getIdTokenResult()
        .then((token) => {
            if (!!token.claims.api_key) {
                fetch(`${ce_default_url}/api/portfolios/${selectedPortfolio}/datasets/financial/export`, {
                    method: 'GET',
                    headers: {
                        'X-SustGlobal-APIKey': token.claims.api_key,
                        'X-SustGlobal-Project': `${selectedProject}`,
                    },
                })
                    .then((response) => response.blob())
                    .then((blob) => {
                        const url = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = url;
                        link.download = `${selectedPortfolio}-vs.zip`;
                        document.body.appendChild(link);
                        link.click();
                        link.remove()
                        window.URL.revokeObjectURL(url);
                    })
                    .catch((error) => {
                        console.error(`error while fetching from API: ${error}`)
                    });
            } else {
                console.error('api key not available for user')
            }
        })
        .catch((error) => {
            console.error(`error while fetching from API: ${error}`)
        })
  }

  const handleFunctionOption = (option) => {
    setLayerFunctionName(option);
    const item = functionOptions.find(item => item.displayName === option);
    console.log(item);
    setFunctionLayer(item);
  }

  return (
    <div className="layer-select-panel">
      <div className="filter-container">
        <div className="selector-container">
        <DropdownMenu
            label={"Portfolio"}
            style={{ width: "160px" }}
            options={portfolios}
            onSelect={setSelectedPortfolio}
          />
        </div>
        <div className="selector-container">
          <DropdownMenu
            label={"Models"}
            style={{ width: "160px" }}
            options={functionOptions.map(option => option.displayName)}
            onSelect={handleFunctionOption}
          />
        </div>
        <div className="selector-container">
          <DropdownMenu
            label={"Boundaries"}
            style={{ width: "160px" }}
            options={["Admin 2", "Admin 3"]}
            onSelect={setSelectedBoundary}
          />
        </div>
        <div className="selector-container">
          <button className="summary-button" onClick={() => composeMainMap()}>Compose</button>
        </div>
        <div className="selector-container">
          <button className="summary-button" onClick={() => downloadFile(user, selectedProject, selectedPortfolio)}>Download</button>
        </div>
      </div>
    </div>
  );
};

export default ComposeLayerSelector;
