import React, { useState, useEffect, useRef, useContext } from "react";
import { Map, Source, Layer } from "react-map-gl";
import TopBarProgress from "react-topbar-progress-indicator";
import { lineString, bbox } from "turf";
import MapFilter from "../Layout/MapFilter";
import {
  assetTypeStyle,
  assetTypeStyleNoWeights,
  hazardLabelStyle,
  hazardLabelStyleNoWeights,
  pointBorder,
  pointBorderNoWeights,
} from "../Layout/LayerStyle";

import AdminPolygon from "./AdminPolygon";
import IndexMapLegend from "./IndexMapLegend";
import GraphPanel from "./GraphPanel";
import {authContext} from "../../context/authContext";
import {ce_default_url} from '../../context/firebase-config'
import hazardScoreMetadata from "../../data/geojson/all_region_metadata.json";



const IndexMap = (props) => {
  const { hazards, selectedProject } = props;

  const temperatureScenarios = ["SSP2-RCP4.5 : 2.4C", "SSP5-RCP8.5 : 4.3C"];
  const yearRanges = ["15yr", "30yr"];

  const [portfolios, setPortfolios] = useState([]);
  const [selectedPortfolio, setSelectedPortfolio] = useState(portfolios.length === 0 ? "" : portfolios[0]);
  const [selectedPortfolioData, setSelectedPortfolioData] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [types, setTypes] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("All");
  const [selectedCompanyData, setSelectedCompanyData] = useState();
  const [peerComparison, setPeerComparison] = useState();
  const [histBins, setHistBins] = useState();
  const [selectedHazard, setSelectedHazard] = useState(null);
  const [selectedTemperatureScenario, setSelectedTemperatureScenario] = useState("4.3°C");
  const [selectedYearRange, setSelectedYearRange] = useState(yearRanges[1]);
  const [graphPanelVisible, setGraphPanelVisible] = useState(false);
  const [mapReady, setMapReady] = useState(false);

  const [activeMapData, setActiveMapData] = useState(null);
  const [assetPointStyle, setAssetPointStyle] = useState(assetTypeStyle);
  const [pointBorderStyle, setPointBorderStyle] = useState(pointBorder);
  const [selectedAssetProperties, setSelectedAssetProperties] = useState(null);    

  const [adminPolyScores, setAdminPolyScores] = useState([]);
  const [adminTesScores, setAdminTesScores] = useState([]);
  const [useTes, setUseTes] = useState(true);

  const [assetPointsVisible, setAssetPointsVisible] = useState(true);
  const [assetPointsWeightVisible, setAssetPointsWeightVisible] = useState(true);
  const [popupInfo, setPopupInfo] = useState(null);

  const user = useContext(authContext);

  useEffect(() => {
    setMapReady(false)
    if (selectedProject) {
      user && user.getIdTokenResult()
      .then((token) => {
          if (!!token.claims.api_key) {
              fetch(`${ce_default_url}/api/portfolios`, {
                  method: 'GET',
                  headers: {
                      'X-SustGlobal-APIKey': token.claims.api_key,
                      'X-SustGlobal-Project': selectedProject,
                  }
              })
                  .then(r => r.json())
                  .then(r => {
                    if (!r.errors) {
                      let ports = []
                      r.forEach((p) => {
                        ports.push(p.portfolio_name)
                      })
                      setPortfolios(ports)
                      if (ports.length !== 0) {
                        setSelectedPortfolio(ports[0])
                      } else {
                        setMapReady(true)
                      }
                    }
                  })
          } else {
              console.error('api key not available for user')
          }
      })
      .catch((error) => {
          console.error(`[APPS_LIST] Error while fetching portfolio data: ${error}`)
      });
    }
  }, [user, selectedProject]);

  useEffect(() => {
    if (selectedPortfolio) {
      user && user.getIdTokenResult()
      .then((token) => {
          if (!!token.claims.api_key) {
              fetch(`${ce_default_url}/api/portfolios/${selectedPortfolio}/company/financial`, {
                  method: 'GET',
                  headers: {
                      'X-SustGlobal-APIKey': token.claims.api_key,
                      'X-SustGlobal-Project': selectedProject,
                  }
              })
              .then(r => r.json())
              .then(r => {
                if (r['payload']) {
                  let p = r['payload']['companies']
                  p = p.sort()
                  p.unshift("All")
                  setCompanies(p)
                  setTypes(r['payload']['types'])
                } else {
                  setCompanies([""])
                  setTypes([])
                }
              })
          } else {
              console.error('api key not available for user')
          }
      })
      .catch((error) => {
          console.error(`[APPS_LIST] Error while fetching company data: ${error}`)
      });
    }
  }, [user, selectedProject, selectedPortfolio]);

  useEffect(() => {
    if (selectedPortfolio) {
      user && user.getIdTokenResult()
        .then((token) => {
            if (!!token.claims.api_key) {
                let scenarioLabel =  selectedTemperatureScenario === "4.3°C" ? "ssp585" : "ssp245";
                fetch(`${ce_default_url}/api/portfolios/${selectedPortfolio}/company/${selectedCompany}/scenario/${scenarioLabel}/financial/assets`, {
                    method: 'GET',
                    headers: {
                        'X-SustGlobal-APIKey': token.claims.api_key,
                        'X-SustGlobal-Project': selectedProject,
                    }
                })
                .then(r => r.json())
                .then(r => {
                  let mapData = {
                    type: "FeatureCollection",
                    features: [],
                  };
                  if (r['payload']) {
                    let p = r['payload'];
                    setSelectedPortfolioData(p);
                    p.forEach((item) => {
                      let finWeight = 0.0
                      if (item.financial_exposure_weight) {
                        finWeight = Number(item.financial_exposure_weight)
                      }
                      let typ = item.typ
                      if (typ) {
                        typ = typ.trim()
                      }
                      mapData.features.push({
                        "geometry": item.geo,
                        "properties": {
                          "entityId": item.entity_id,
                          "entityName": item.entity_name,
                          "companyName": item.company_name,
                          "address": item.address,
                          "index": item.portfolio_index,
                          "wildfireSummaryLabel": item.summary_label.wildfire,
                          "cycloneSummaryLabel": item.summary_label.cyclone,
                          "floodSummaryLabel": item.summary_label.flood,
                          "heatwaveSummaryLabel": item.summary_label.heatwave,
                          "SLRSummaryLabel": item.summary_label.SLR,
                          "water_stressSummaryLabel": item.summary_label.water_stress,
                          "type": typ,
                          "financialExposureWeight": finWeight,
                        }
                      });
                    });
                  } else {
                    console.log(r['errors'], selectedPortfolio)
                  }
                  setActiveMapData(mapData);
                  setMapReady(true);
                })
            } else {
                console.error('api key not available for user')
            }
        })
        .catch((error) => {
            console.error(`[APPS_LIST] Error while fetching assets: ${error}`)
        })
      }
  }, [user, selectedTemperatureScenario, selectedPortfolio, selectedProject, selectedCompany]);

  // Set active map data based on selected hazard and temperature scenario
  useEffect(() => {
    if (selectedHazard) {
      const hazardLabel = `${selectedHazard}SummaryLabel`;
      const newPointStyle = JSON.parse(JSON.stringify(assetPointsWeightVisible ? hazardLabelStyle : hazardLabelStyleNoWeights));
      newPointStyle.paint["circle-color"][1][1] = hazardLabel;
      setAssetPointStyle(newPointStyle);
      setPointBorderStyle(assetPointsWeightVisible ? pointBorder : pointBorderNoWeights);
    } else {
      const newTypeStyle = JSON.parse(JSON.stringify(assetPointsWeightVisible ? assetTypeStyle : assetTypeStyleNoWeights));
      if (types && types.length !== 0) {
        const temp = newTypeStyle.paint["circle-color"].pop();
        newTypeStyle.paint["circle-color"].pop();
        newTypeStyle.paint["circle-color"].pop();
        types.forEach((t) => {
          newTypeStyle.paint["circle-color"].push(t.name, t.color);
        });
        newTypeStyle.paint["circle-color"].push(temp);
      }
      setAssetPointStyle(newTypeStyle);
      setPointBorderStyle(assetPointsWeightVisible ? pointBorder : pointBorderNoWeights);
    }
  }, [
    selectedCompany,
    selectedHazard,
    types,
    selectedTemperatureScenario,
    assetPointsWeightVisible,
  ]);


  useEffect(() => {
    const importAll = (r) => r.keys().map(r);
    const files = importAll(require.context('../../data/geojson/admin_poly_score', false, /\.geojson$/));
    setAdminPolyScores(files);
    const tessellated_files = importAll(require.context('../../data/geojson/admin_tes_score', false, /\.geojson$/));
    setAdminTesScores(tessellated_files);
  }, []);

  
  // Initial Map state
  const mapRef = useRef(null);
  const accessToken = process.env.REACT_APP_MAPBOX_TOKEN; 
  const [viewState, setViewState] = useState({
    latitude: 40.245,
    longitude: -105.79,
    zoom: 3.5,
  });

  // Zoom to active map data extent
  useEffect(() => {
    if (selectedPortfolioData && mapRef.current) {
      let pointsList = [];
      selectedPortfolioData.forEach((item) => {
        pointsList.push(item.geo.coordinates)
      });
      const [minLng, minLat, maxLng, maxLat] = bbox(lineString(pointsList));
      mapRef.current.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat],
        ],
        { padding: 200, duration: 500, offset: [100, 0] }
      );
    }
  }, [selectedPortfolioData]);

  useEffect(() => {
    if (selectedPortfolio) {
      let scenarioLabel =  selectedTemperatureScenario === "4.3°C" ? "ssp585" : "ssp245";
      user && user.getIdTokenResult()
          .then((token) => {
              if (!!token.claims.api_key) {
                  fetch(`${ce_default_url}/api/portfolios/${selectedPortfolio}/company/${selectedCompany}/scenario/${scenarioLabel}/financial`, {
                      method: 'GET',
                      headers: {
                          'X-SustGlobal-APIKey': token.claims.api_key,
                          'X-SustGlobal-Project': selectedProject,
                      }
                  })
                      .then(r => r.json())
                      .then(r => setSelectedCompanyData(r['payload']))
              } else {
                  console.error('api key not available for user')
              }
          })
          .catch((error) => {
              console.error(`[APPS_LIST] Error while fetching asset level data for hazard data: ${error}`)
          });
      }
  }, [user, selectedCompany, selectedPortfolio, selectedProject, selectedTemperatureScenario])
   
  useEffect(() => {
    if (selectedCompanyData) {
      const peers = selectedCompanyData.map(data => {
        const wildfire2050_sd = data['sd_2050']['wildfire'];
        const flood2050_sd = data['sd_2050']['flood'];
        const cyclone2050_sd = data['sd_2050']['cyclone'];
        const heatwave2050_sd = data['sd_2050']['heatwave'];
        const total_sd = wildfire2050_sd + flood2050_sd + cyclone2050_sd + heatwave2050_sd;
        const wildfire2050_bi = data['bi_2050']['wildfire'];
        const flood2050_bi = data['bi_2050']['flood'];
        const cyclone2050_bi = data['bi_2050']['cyclone'];
        const heatwave2050_bi = data['bi_2050']['heatwave'];
        const total_bi = wildfire2050_bi + flood2050_bi + cyclone2050_bi+ heatwave2050_bi;
        return {
            label: data['entity_id'] ? data['entity_id'] : data['portfolio_index'],
            "sd": {
              Wildfire: wildfire2050_sd,
              Flood: flood2050_sd,
              Cyclone: cyclone2050_sd,
              Heatwave: heatwave2050_sd,
              total_risk: total_sd
            },
            "bi": {
              Wildfire: wildfire2050_bi,
              Flood: flood2050_bi,
              Cyclone: cyclone2050_bi,
              Heatwave: heatwave2050_bi,
              total_risk: total_bi,
            }
          };
        });
  
      setPeerComparison({
        "peers": peers,
        "company": selectedCompany
      })
    }
  }, [user, selectedCompanyData, selectedCompany, selectedPortfolio])

  useEffect(() => {
    if (selectedCompanyData) {
      let histogramData = {
        "wildfire": [],
        "cyclone": [],
        "flood": [],
        "heatwave": [],
        "waterStress": [],
        "seaLevelRise": [],
      };
      selectedCompanyData.forEach((feature) => {
        histogramData.wildfire.push(feature.summary_score.wildfire);
        histogramData.cyclone.push(feature.summary_score.cyclone);
        histogramData.flood.push(feature.summary_score.flood);
        histogramData.heatwave.push(feature.summary_score.heatwave);
        histogramData.waterStress.push(feature.summary_score.water_stress);
        histogramData.seaLevelRise.push(feature.summary_score.SLR);
      });

      let bins = {};
      bins.wildfire = createBins(histogramData.wildfire);
      bins.cyclone = createBins(histogramData.cyclone);
      bins.flood = createBins(histogramData.flood);
      bins.heatwave = createBins(histogramData.heatwave);
      bins.waterStress = createBins(histogramData.waterStress);
      bins.seaLevelRise =  createBins(histogramData.seaLevelRise);

      setHistBins(bins);
    }
  }, [user, selectedCompanyData, selectedPortfolio])

  const createBins = (data) => {
      const maxScore = Math.max(...data);
      const minScore = Math.min(...data);
      const binSize = (maxScore - minScore) / 10;
      let bins = new Array(10).fill(0);
      data.forEach((score) => {
        const binIndex = Math.min(Math.floor((score - minScore) / binSize), 9); 
        bins[binIndex]++;
      });
      let breaks = [];
      breaks.push(minScore);
      for (let i = 1; i < 10; i++) {
        breaks.push(minScore + i * binSize);
      }
      return [bins, breaks];
  }

  const onLayerClick = (event) => {
    if (popupInfo) {
      setPopupInfo(null);
    }
    const features = mapRef.current.queryRenderedFeatures(event.point, {
      layers: ["point-layer"],
    });
    if (features.length > 0) {
      const feature = features[0];
      setPopupInfo({
        longitude: event.lngLat.lng,
        latitude: event.lngLat.lat,
        properties: feature.properties,
      });
      setSelectedAssetProperties(feature.properties);
      setGraphPanelVisible(true);
    } else {
      setPopupInfo(null);
    }
  };

  const handleGraphPanelClose = () => {
    setGraphPanelVisible(false);
    setSelectedAssetProperties(null);
    setPopupInfo(null);
  }

  if (mapReady) {
    return (
      <div style={{ width: "100%", height: "100%" }}>
          { mapReady &&
          <Map
            {...viewState}
            ref={mapRef}
            mapStyle={"mapbox://styles/mapbox/dark-v9"}
            onMove={(event) => setViewState(event.viewState)}
            mapboxAccessToken={accessToken}
            interactive={true}
            scrollZoom={true}
            touchZoomRotate={true}
            dragPan={true}
            onClick={onLayerClick}
          >
          {selectedHazard && useTes && (
              <>
              {adminTesScores.map((poly, index) => (
                <AdminPolygon
                  key={index}
                  hazardScoreMetadata={hazardScoreMetadata}
                  selectedHazard={selectedHazard}
                  selectedTemperatureScenario={selectedTemperatureScenario}
                  yearRange={selectedYearRange}
                  adminPolygonData={poly}
                  source_id={`admin-polygon-${index}`}
              />
              ))}
            {assetPointsVisible && (
            <>
            <Source id="point-layer" type="geojson" data={activeMapData}>
              <Layer {...pointBorderStyle} />
              <Layer {...assetPointStyle} />
            </Source>
            </>
          )}
          </>  
          )
          }        
            {selectedHazard && !useTes && (
              <>
              {adminPolyScores.map((poly, index) => (
                <AdminPolygon
                  key={index}
                  hazardScoreMetadata={hazardScoreMetadata}
                  selectedHazard={selectedHazard}
                  selectedTemperatureScenario={selectedTemperatureScenario}
                  yearRange={selectedYearRange}
                  adminPolygonData={poly}
                  source_id={`admin-polygon-${index}`}
              />
              ))}
            {assetPointsVisible && (
            <>
            <Source id="point-layer" type="geojson" data={activeMapData}>
              <Layer {...pointBorderStyle} />
              <Layer {...assetPointStyle} />
            </Source>
            </>
          )}
          </>  
          )
          }
          
          {!selectedHazard && assetPointsVisible && (
            <Source id="point-layer" type="geojson" data={activeMapData}>
              <Layer {...pointBorderStyle} />
              <Layer {...assetPointStyle} />
            </Source>
          )}

          <MapFilter
            portfolios={portfolios}
            companies={companies}
            selectedProject={selectedProject}
            selectedPortfolio={selectedPortfolio}
            setSelectedPortfolio={setSelectedPortfolio}
            setSelectedCompany={setSelectedCompany}
            setGraphPanelVisible={setGraphPanelVisible}
          />
          <IndexMapLegend
            hazards={hazards}
            hazardScoreMetadata={hazardScoreMetadata}
            selectedHazard={selectedHazard}
            setSelectedHazard={setSelectedHazard}
            temperatureScenarios={temperatureScenarios}
            selectedTemperatureScenario={selectedTemperatureScenario}
            setSelectedTemperatureScenario={setSelectedTemperatureScenario}
            yearRanges={yearRanges}
            selectedYearRange={selectedYearRange}
            setSelectedYearRange={setSelectedYearRange}
            setAssetPointStyle={setAssetPointStyle}
            setActiveMapData={setActiveMapData}
            assetPointsVisible={assetPointsVisible}
            setAssetPointsVisible={setAssetPointsVisible}
            assetPointsWeightVisible={assetPointsWeightVisible}
            setAssetPointsWeightVisible={setAssetPointsWeightVisible}
            useTes={useTes}
            setUseTes={setUseTes}
            types={types}
          />
          {graphPanelVisible && (
            <GraphPanel
              handleGraphPanelClose={handleGraphPanelClose}
              selectedAssetProperties={selectedAssetProperties}
              setSelectedAssetProperties={setSelectedAssetProperties}
              selectedTemperatureScenario={selectedTemperatureScenario}
              selectedPortfolio={selectedPortfolio}
              selectedProject={selectedProject}
              peerComparison={peerComparison}
              selectedCompany={selectedCompany}
              histBins={histBins}
            />
          )}
        </Map> }
      </div>
    );
  } else {
    return (
      <div style={{ width: "100%", height: "100%" }}>
        <TopBarProgress />
        <Map
        mapboxAccessToken={accessToken}
        initialViewState={viewState}
        style={{opacity: 0.6, pointerEvents: "none"}}
        mapStyle="mapbox://styles/mapbox/dark-v9">
          <MapFilter
            portfolios={portfolios}
            companies={companies}
            selectedPortfolio={selectedPortfolio}
            setSelectedPortfolio={setSelectedPortfolio}
            setSelectedCompany={setSelectedCompany}
            setGraphPanelVisible={setGraphPanelVisible}
          />
          <IndexMapLegend
            hazards={hazards}
            hazardScoreMetadata={hazardScoreMetadata}
            selectedHazard={selectedHazard}
            setSelectedHazard={setSelectedHazard}
            temperatureScenarios={temperatureScenarios}
            selectedTemperatureScenario={selectedTemperatureScenario}
            setSelectedTemperatureScenario={setSelectedTemperatureScenario}
            yearRanges={yearRanges}
            selectedYearRange={selectedYearRange}
            setSelectedYearRange={setSelectedYearRange}
            setAssetPointStyle={setAssetPointStyle}
            setActiveMapData={setActiveMapData}
            assetPointsVisible={assetPointsVisible}
            setAssetPointsVisible={setAssetPointsVisible}
            assetPointsWeightVisible={assetPointsWeightVisible}
            setAssetPointsWeightVisible={setAssetPointsWeightVisible}
            useTes={useTes}
            setUseTes={setUseTes}
            types={types}
          />
        </Map>
      </div>)

  }
};

export default IndexMap;
