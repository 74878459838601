import React, { useState, useEffect, useRef, useContext } from "react";
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import {
  outlineStyle,
  assetTypeStyle,
  assetTypeStyleNoWeights,
  pointBorder,
  pointBorderNoWeights,
} from "../Layout/ComposeLayerStyle";
import {authContext} from "../../context/authContext";
import {ce_default_url} from '../../context/firebase-config'

import ComposeMapLegend from "./ComposeMapLegend";

const MainComposeMap = (props) => {
  const {
    selectedProject,
    selectedPortfolio,
    selectedFunction,
    composePolys,
    adminLevel,
    adminStyling,
    sourceId,
    setParentViewState,
    showMainMap,
   } = props;

  const [selectedCompany, setSelectedCompany] = useState();
  const [selectedHazard, setSelectedHazard] = useState(null);
  const [types, setTypes] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedTemperatureScenario, setSelectedTemperatureScenario] = useState("4.3°C");
  const [selectedPortfolioData, setSelectedPortfolioData] = useState(null);

  const [activeMapData, setActiveMapData] = useState(null);
  const [assetPointStyle, setAssetPointStyle] = useState(assetTypeStyle);
  const [assetPointBorderStyle, setAssetPointBorderStyle] = useState(pointBorder);
  const [assetPointsVisible, setAssetPointsVisible] = useState(true);
  const [assetPointsWeightVisible, setAssetPointsWeightVisible] = useState(false);

  const accessToken = process.env.REACT_APP_ESRI_TOKEN; 
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [sources, setSources] = useState({});

  const user = useContext(authContext);
  useEffect(() => {
    if (selectedPortfolio) {
      user && user.getIdTokenResult()
      .then((token) => {
          if (!!token.claims.api_key) {
              fetch(`${ce_default_url}/api/portfolios/${selectedPortfolio}/company/financial`, {
                  method: 'GET',
                  headers: {
                      'X-SustGlobal-APIKey': token.claims.api_key,
                      'X-SustGlobal-Project': selectedProject,
                  }
              })
              .then(r => r.json())
              .then(r => {
                if (r['payload']) {
                  let p = r['payload']['companies']
                  p = p.sort()
                  p.unshift("All")
                  setCompanies(p)
                  setTypes(r['payload']['types'])
                } else {
                  setCompanies([""])
                  setTypes([])
                }
              })
          } else {
              console.error('api key not available for user')
          }
      })
      .catch((error) => {
          console.error(`[APPS_LIST] Error while fetching company data: ${error}`)
      });
    }
  }, [user, selectedProject, selectedPortfolio]);

  useEffect(() => {
    if (selectedPortfolio) {
      user && user.getIdTokenResult()
        .then((token) => {
            if (!!token.claims.api_key) {
                let scenarioLabel =  selectedTemperatureScenario === "4.3°C" ? "ssp585" : "ssp245";
                fetch(`${ce_default_url}/api/portfolios/${selectedPortfolio}/company/${selectedCompany}/scenario/${scenarioLabel}/financial/assets`, {
                    method: 'GET',
                    headers: {
                        'X-SustGlobal-APIKey': token.claims.api_key,
                        'X-SustGlobal-Project': selectedProject,
                    }
                })
                .then(r => r.json())
                .then(r => {
                  let mapData = {
                    type: "FeatureCollection",
                    features: [],
                  };
                  if (r['payload']) {
                    let p = r['payload'];
                    setSelectedPortfolioData(p);
                    p.forEach((item) => {
                      let finWeight = 0.0
                      if (item.financial_exposure_weight) {
                        finWeight = Number(item.financial_exposure_weight)
                      }
                      let typ = item.typ
                      if (typ) {
                        typ = typ.trim()
                      }
                      mapData.features.push({
                        "geometry": item.geo,
                        "properties": {
                          "entityId": item.entity_id,
                          "entityName": item.entity_name,
                          "companyName": item.company_name,
                          "address": item.address,
                          "index": item.portfolio_index,
                          "wildfireSummaryLabel": item.summary_label.wildfire,
                          "cycloneSummaryLabel": item.summary_label.cyclone,
                          "floodSummaryLabel": item.summary_label.flood,
                          "heatwaveSummaryLabel": item.summary_label.heatwave,
                          "SLRSummaryLabel": item.summary_label.SLR,
                          "water_stressSummaryLabel": item.summary_label.water_stress,
                          "type": typ,
                          "financialExposureWeight": finWeight,
                        }
                      });
                    });
                  } else {
                    console.log(r['errors'], selectedPortfolio)
                  }
                  setActiveMapData(mapData);
                })
            } else {
                console.error('api key not available for user')
            }
        })
        .catch((error) => {
            console.error(`[APPS_LIST] Error while fetching assets: ${error}`)
        })
      }
  }, [user, selectedTemperatureScenario, selectedPortfolio, selectedProject, selectedCompany]);

  // Set active map data
  useEffect(() => {
    const newTypeStyle = JSON.parse(JSON.stringify(assetPointsWeightVisible ? assetTypeStyle : assetTypeStyleNoWeights));
    if (types && types.length !== 0) {
      const temp = newTypeStyle.paint["circle-color"].pop();
      newTypeStyle.paint["circle-color"].pop();
      newTypeStyle.paint["circle-color"].pop();
      types.forEach((t) => {
        newTypeStyle.paint["circle-color"].push(t.name, t.color);
      });
      newTypeStyle.paint["circle-color"].push(temp);
    }
    setAssetPointStyle(newTypeStyle);
    setAssetPointBorderStyle(assetPointsWeightVisible ? pointBorder : pointBorderNoWeights);
  }, [types, assetPointsWeightVisible]);

  // // Zoom to active map data extent
  // useEffect(() => {
  //   if (activeMapData && mapRef.current) {
  //     const pointsList = activeMapData.features.map(
  //       (feature) => feature.geometry.coordinates
  //     );
  //     const [minLng, minLat, maxLng, maxLat] = bbox(lineString(pointsList));
  //     mapRef.current.fitBounds(
  //       [
  //         [minLng, minLat],
  //         [maxLng, maxLat],
  //       ],
  //       { padding: 200, duration: 500, offset: [100, 0] }
  //     );
  //   }
  // }, [activeMapData]);

  useEffect(() => {
    if (map.current) return; // stops map from intializing more than once

    map.current = new maplibregl.Map({
      container: mapContainer.current,
      style: `https://basemapstyles-api.arcgis.com/arcgis/rest/services/styles/v2/styles/arcgis/dark-gray?token=${accessToken}`,
      center: [-105.79, 40.245],
      zoom: 3.5,
    });
    map.current.on('move', () => {
      setParentViewState({
        "longitude": map.current.getCenter().lng,
        "latitude":  map.current.getCenter().lat,
        "zoom": map.current.getZoom(),
      });
    })
  }, [accessToken]);

  useEffect(() => {
    map.current.on('load', () => {
      let sources = {"2": [],  "3": []};
      composePolys.forEach((level) => {
        level.forEach((poly) => {
          var data = require(`../../data/geojson/compose_polys/admin${poly.level}/${poly.name}`);
          let srcName = `${sourceId}-${poly.level}-${poly.name}-polys`;
          map.current.addSource(srcName, {
            type: 'geojson',
            data: data,
          });
          sources[`${poly.level}`].push(srcName);
        });
      });
      setSources(sources);
    });
  }, [composePolys]);
  
  useEffect(() => {
    let style = map.current.getStyle();
    if (style) {
      let layers = style.layers.filter(i => i.source ? i.source.includes("points") : false);
      layers.forEach((layer) => {
        map.current.removeLayer(layer.id);
      });

      let sources = Object.keys(style.sources).filter(k => k.includes("points"));
      sources.forEach((src) => {
        map.current.removeSource(src);
      });

      if (!selectedHazard && assetPointsVisible) {
        const srcName = `${sourceId}-points`;
        map.current.addSource(srcName, {
          type: 'geojson',
          data: activeMapData,
        });
        
        const layerName = `${srcName}-layer`
        assetPointStyle.id = layerName;
        assetPointStyle.source = srcName;
        assetPointBorderStyle.id = `border-${layerName}`;
        assetPointBorderStyle.source = srcName;
        map.current.addLayer(assetPointBorderStyle);
        map.current.addLayer(assetPointStyle);
      }
    }
  }, [selectedHazard, assetPointsVisible, activeMapData, assetPointStyle]);

  useEffect(() => {
    let style = map.current.getStyle();
    if (style) {
      let layers = style.layers.filter(i => i.source ? i.source.includes("polys") : false);
      layers.forEach((layer) => {
        map.current.removeLayer(layer.id);
      });

      if (selectedFunction && showMainMap) {
        sources[`${adminLevel}`].forEach((src) => {
          const hazardLabel = selectedFunction.propertyName;
          const min = selectedFunction.min;
          const max = selectedFunction.max;
    
          let newPolygonStyle = {};
          newPolygonStyle = JSON.parse(JSON.stringify(adminStyling[selectedFunction.propertyName]));
          newPolygonStyle.id = `${src}-${hazardLabel}-layer`;
          newPolygonStyle.paint["fill-color"][2][1] = hazardLabel;
          newPolygonStyle.paint["fill-color"][5] = min;     
          newPolygonStyle.paint["fill-color"][7] = max;
          newPolygonStyle.source = `${src}`;

          let newOutlineStyle = {};
          newOutlineStyle = JSON.parse(JSON.stringify(outlineStyle));
          newOutlineStyle.id =  `${src}-${hazardLabel}-outline-layer`;
          newOutlineStyle.source = `${src}`;
    
          if (map.current.getLayer(`${sourceId}-points-layer`)) {
            map.current.addLayer(newPolygonStyle, `border-${sourceId}-points-layer`);
            map.current.addLayer(newOutlineStyle, `border-${sourceId}-points-layer`);
          } else {
            map.current.addLayer(newPolygonStyle);
            map.current.addLayer(newOutlineStyle);
          }

          map.current.on('click', `${src}-${hazardLabel}-layer`, (e) => {
            let val = e.features[0].properties[selectedFunction.propertyName];
            let descriptor = "";
            if ('zip' in e.features[0].properties) {
              descriptor = e.features[0].properties['zip'];
            } else if ('county' in e.features[0].properties) {
              descriptor = `${e.features[0].properties['county']}, ${e.features[0].properties['state']}`;
            }
            let description = descriptor === "" ? "" : `<strong>${descriptor}</strong><br>`;
            if (selectedFunction.unit === "%" && val < 1) {
              val = val * 100;
              description += `${val === 0 ? val : val.toFixed(4)}${selectedFunction.unit}`;
            } else if (selectedFunction.unit === "%" && val >= 1) {
              description += `${val.toFixed(1)}${selectedFunction.unit}`;
            } else if (selectedFunction.unit === "$") {
              description += `${selectedFunction.unit}${val.toFixed(2)}`;
            } else {
              description += `${selectedFunction.unit} ${val}`;
            }
            if (newPolygonStyle.id.includes(selectedFunction.propertyName)) {
              const popup = new maplibregl.Popup({
                closeButton: false,
                closeOnClick: true
              });
              popup.setLngLat([e.lngLat.lng, e.lngLat.lat]).setHTML(description).addTo(map.current);
            }
          });
        });
      }
    }
  }, [selectedFunction, adminLevel, showMainMap]);

  return (
    <div ref={mapContainer} className="main-map">
      <ComposeMapLegend
        adminStyling={adminStyling}
        isMainMap={true}
        layerVisible={showMainMap}
        selectedLayer={selectedFunction}
        setAssetPointStyle={setAssetPointStyle}
        setActiveMapData={setActiveMapData}
        assetPointsVisible={assetPointsVisible}
        setAssetPointsVisible={setAssetPointsVisible}
        assetPointsWeightVisible={assetPointsWeightVisible}
        setAssetPointsWeightVisible={setAssetPointsWeightVisible}
        types={types}
      />
    </div>
  );
};

export default MainComposeMap;
