import React, {useContext, useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import {FaExternalLinkAlt, FaInfoCircle, FaUserCircle} from "react-icons/fa";
import SustLogo from '../../data/images/white-logo-small.png';
import './TopNavBar.css';
import {authContext} from "../../context/authContext";
import {ce_default_url} from '../../context/firebase-config'

const TopNavBar = (props) => {
    const { selectedProject, setSelectedProject } = props;
    const user = useContext(authContext);
    const [superUser, setSuperUser] = useState(false);
    const [projects, setProjects] = useState([])
    const gw_application_id = 3

    useEffect(() => {
        user && user.getIdTokenResult()
            .then((token) => {
                if (!!token.claims.api_key) {
                    fetch(`${ce_default_url}/api/projects?application_id=${gw_application_id}`, {
                        method: 'GET',
                        headers: {
                            'X-SustGlobal-APIKey': token.claims.api_key,
                        }
                    })
                        .then(r => r.json())
                        .then(r => {
                            let proj = r.map(p => p.project_name)
                            setProjects(proj)
                            if (proj.indexOf(selectedProject) === -1 ) {
                                setSelectedProject(proj[0])
                            }
                        })
                } else {
                    console.error('api key not available for user')
                }
            })
            .catch((error) => {
                console.error(`[PROJECT_LIST] Error while fetching project list: ${error}`)
            })
    }, [user, setSelectedProject, selectedProject])

    useEffect(() => {
        if (user) {
            user.getIdTokenResult()
                .then((token) => {
                    if (!!token.claims.is_superuser) {
                        setSuperUser(token.claims.is_superuser)
                    } else {
                        console.error('superuser status not available for user')
                    }
                })
                .catch((error) => {
                    console.error(`error while retrieving superuser status: ${error}`)
                });
        }
    }, [user])


    let projectsToShow = <ProjectsToShow 
                            projects={projects} 
                            selectedProject={selectedProject} 
                            setSelectedProject={setSelectedProject} />
    let apps = AppsToShow()
    return (
        <>
            <Navbar expand="sm" className="bg-dark" data-bs-theme="dark"
                    style={{height: '70px', backgroundColor: '#212527'}}>
                <Container style={{background: '#212527', width: '1275px'}}>
                    <Navbar.Brand className='navbar-brand' style={{width: '260px'}}>
                        <img alt="SUST LOGO" src={SustLogo} width="100%" height="auto"/>{' '}
                    </Navbar.Brand>
                    {/*<Navbar.Toggle aria-controls="basic-navbar-nav"/>*/}
                    <Navbar.Collapse className="justify-content-start">
                        <Nav className={"me-2"} style={{
                            width: '390px',
                            height: '38px',
                            lineHeight: '1.5',
                            textAlign: 'left',
                            fontSize: '1.2rem',
                            fontWeight: '500',
                        }}>
                            <Nav.Link href="/index">Dashboard</Nav.Link>
                            <Nav.Link href="/portfolios">Portfolios</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                    <Navbar.Collapse id="basic-navbar-nav">
                        {useMenu(user, superUser, apps, projectsToShow, selectedProject)}
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
};

const ProjectsToShow = (props) => {
    const { projects, selectedProject, setSelectedProject } = props
    if (projects.length > 0) {
        return projects.map(
            p => {
                return (
                    <Dropdown.Item key={p} active={p === selectedProject} onClick={() => setSelectedProject(p)} style={{width: '251px'}}>{p}</Dropdown.Item>
                )
            }
        )
    }
}

const AppsToShow = () => {
    const user = useContext(authContext);
    const [apps, setApps] = useState([])

    useEffect(() => {
        user && user.getIdTokenResult()
            .then((token) => {
                if (!!token.claims.api_key) {
                    fetch(`${ce_default_url}/api/myapps`, {
                        method: 'GET',
                        headers: {
                            'X-SustGlobal-APIKey': token.claims.api_key,
                            'X-SustGlobal-Project': 'sust-ptg',
                        }
                    })
                        .then(r => r.json())
                        .then(r => setApps(r))
                } else {
                    console.error('api key not available for user')
                }
            })
            .catch((error) => {
                console.error(`[APPS_LIST] Error while fetching app list: ${error}`)
            })
    }, [user])

    if (apps.length > 0) {
        return apps.map(
            (app, index) => {
                return (
                    <Dropdown.Item active={app.name === "Visual Summary"} key={app.name} href={app.url} disabled={!app.has_access}
                                   style={{width: '200px'}}>{app.name}</Dropdown.Item>
                )
            }
        )
    }
}

const useMenu = (user, superUser, apps, projects, project) => {
    if (user) {
        const UserDropdownTitle = (<FaUserCircle size={25}/>);
        const infoDropdownTitle = (<FaInfoCircle size={25}/>);
        const externalLink = (<FaExternalLinkAlt size={15}/>);
        return (
            <>
                <Nav className="me-2" style={{
                    border: '1px solid #505050',
                    borderRadius: '4px',
                    width: '200px',
                    height: '38px',
                    lineHeight: '1.5'
                }}>
                    <Dropdown as={ButtonGroup}>
                        <Button style={{
                            fontWeight: '500',
                            backgroundColor: '#212529',
                            border: '0px',
                            borderRadius: '4px',
                            width: '144px',
                            color: '#ffffff8c'
                        }}>Visual Summary</Button>
                        <Dropdown.Toggle split style={{
                            textAlign: 'right',
                            backgroundColor: '#212529',
                            border: '0px',
                            width: '50px',
                            color: '#ffffff8c'
                        }}/>
                        <Dropdown.Menu style={{backgroundColor: '#212529', textAlign: 'left'}}>
                            {apps}
                        </Dropdown.Menu>
                    </Dropdown>
                </Nav>

                <Nav className="me-2" style={{
                    border: '1px solid #505050',
                    borderRadius: '4px',
                    width: '301px',
                    height: '38px',
                    lineHeight: '1.5'
                }}>
                    <Dropdown as={ButtonGroup}>
                        <Button style={{
                            fontWeight: '500',
                            backgroundColor: '#212529',
                            border: '0px',
                            borderRadius: '4px',
                            width: '160px',
                            color: '#ffffff8c'
                        }}>{!project || project === "null" ? "Project" : project}</Button>
                        <Dropdown.Toggle split style={{
                            textAlign: 'right',
                            backgroundColor: '#212529',
                            border: '0px',
                            width: '140px',
                            color: '#ffffff8c'
                        }}>
                        </Dropdown.Toggle>
                        <Dropdown.Menu style={{backgroundColor: '#212529', textAlign: 'left'}}>
                            {projects}
                        </Dropdown.Menu>
                    </Dropdown>
                </Nav>

                <Nav className="me-0">
                    <Dropdown as={ButtonGroup}>
                        <Dropdown.Toggle
                            style={{textAlign: 'right', backgroundColor: '#212529', color: '#ffffff8c', border: '0px'}}>
                            {infoDropdownTitle}
                        </Dropdown.Toggle>
                        <Dropdown.Menu style={{backgroundColor: '#212529', textAlign: 'left'}}>
                            <Dropdown.Item href="https://developers.sustglobal.com/user-guide" target={"_blank"}
                                           style={{textDecoration: 'none'}}>
                                {" Climate Explorer User Guide "}
                                {externalLink}
                            </Dropdown.Item>
                            <Dropdown.Item href="https://developers.sustglobal.com/" target={"_blank"}>
                                {' Sust Global Developer Center '}
                                {externalLink}
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Nav>

                <Nav className="me-0">
                    <Dropdown as={ButtonGroup}>
                        <Dropdown.Toggle
                            style={{textAlign: 'right', backgroundColor: '#212529', color: '#ffffff8c', border: '0px'}}>
                            {UserDropdownTitle}
                        </Dropdown.Toggle>
                        <Dropdown.Menu style={{backgroundColor: '#212529', textAlign: 'left'}}>
                            <Dropdown.Item href="/account/profile">User Profile</Dropdown.Item>
                            <Dropdown.Item href="/logout">Log Out</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Nav>
            </>
        )
    } else {
        return (<Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
                <Nav.Link href={'#'}>Login</Nav.Link>
            </Nav>
        </Navbar.Collapse>)
    }
}

export default TopNavBar;
