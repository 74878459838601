import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";
import "./Compose.css";
import MainComposeMap from "./MainComposeMap";
import SubMap from "./SubMap";
import ComposeLayerSelector from "../Layout/ComposeLayerSelector";
import {authContext} from "../../context/authContext";
import {ce_default_url} from '../../context/firebase-config';
import opts from "../../data/geojson/compose_metadata.json";
import {
  floodStyle,
  floodStyleBinary,
  cycloneStyle,
  cycloneStyleBinary,
  wildfireStyle,
  wildfireStyleBinary,
  financialStyle,
  financialStyleHigh,
  resilienceStyle,
  modeledStyle,
  householdStyle,
  demoStyle,
  insuranceStyleRed,
  insuranceStyleOrange,  
} from "../Layout/ComposeLayerStyle";
import { Opacity } from "@mui/icons-material";

const Compose = (props) => {
  const { selectedProject } = props;

  const [portfolios, setPortfolios] = useState([]);
  const [selectedPortfolio, setSelectedPortfolio] = useState(portfolios.length === 0 ? "" : portfolios[0]);
  const [selectedBoundary, setSelectedBoundary] = useState("Admin 2");
  const [adminLevel, setAdminLevel] = useState("2");
  const [composePolys, setComposePolys] = useState([]);

  const [inputLayer1, setInputLayer1] = useState(null);
  const [inputLayer2, setInputLayer2] = useState(null);
  const [inputLayer3, setInputLayer3] = useState(null);
  const [selectedFunction, setLayerFunction] = useState(null);
  const [showMainMap, setShowMainMap] = useState(false);

  const adminStyling = {
    "state_premium_percent_change": financialStyle,
    "state_premium_2023": financialStyleHigh,
    "avg_ann_premium_2023_KeMu": insuranceStyleOrange,
    "population": demoStyle,
    "GDP_in_K": financialStyle,
    "HRCN_EALT": cycloneStyle,
    "CFLD_EALT": cycloneStyle,
    "RFLD_EALT": floodStyle,
    "WFIR_EALT": wildfireStyle,
    "MULTI_EALT": financialStyle,
    "edu_attainment": insuranceStyleOrange,
    "Median_Income_Household": financialStyle,
    "Count_Person": demoStyle,
    "Percent_Person_WithHigherEdu": insuranceStyleOrange,
    "Median_Year_Built": financialStyle,
    "Total_Housing_Units": householdStyle,
    "SOCIAL": resilienceStyle,
    "ECONOM": resilienceStyle,
    "HOUSING": resilienceStyle,
    "COMMCAPITAL": resilienceStyle,
    "INSTITUTIONAL": resilienceStyle,
    "ENVIRONMENT": resilienceStyle,
    "TOTRESIL2020": resilienceStyle,
    "obs_fire_2000": wildfireStyleBinary,
    "obs_fire_2001": wildfireStyleBinary,
    "obs_fire_2002": wildfireStyleBinary,
    "obs_fire_2003": wildfireStyleBinary,
    "obs_fire_2004": wildfireStyleBinary,
    "obs_fire_2005": wildfireStyleBinary,
    "obs_fire_2006": wildfireStyleBinary,
    "obs_fire_2007": wildfireStyleBinary,
    "obs_fire_2008": wildfireStyleBinary,
    "obs_fire_2009": wildfireStyleBinary,
    "obs_fire_2010": wildfireStyleBinary,
    "obs_fire_2011": wildfireStyleBinary,
    "obs_fire_2012": wildfireStyleBinary,
    "obs_fire_2013": wildfireStyleBinary,
    "obs_fire_2014": wildfireStyleBinary,
    "obs_fire_2015": wildfireStyleBinary,
    "obs_fire_2016": wildfireStyleBinary,
    "obs_fire_2017": wildfireStyleBinary,
    "obs_fire_2018": wildfireStyleBinary,
    "obs_fire_2019": wildfireStyleBinary,
    "obs_fire_2020": wildfireStyleBinary,
    "obs_fire_2021": wildfireStyleBinary,
    "obs_fire_2022": wildfireStyleBinary,
    "obs_fire_2000_2022": wildfireStyle,
    "obs_fire_previous_year": wildfireStyle,
    "obs_fire_previous_5year": wildfireStyle,
    "obs_cyclone_2010": cycloneStyleBinary,
    "obs_cyclone_2011": cycloneStyleBinary,
    "obs_cyclone_2012": cycloneStyleBinary,
    "obs_cyclone_2013": cycloneStyleBinary,
    "obs_cyclone_2014": cycloneStyleBinary,
    "obs_cyclone_2015": cycloneStyleBinary,
    "obs_cyclone_2016": cycloneStyleBinary,
    "obs_cyclone_2017": cycloneStyleBinary,
    "obs_cyclone_2018": cycloneStyleBinary,
    "obs_cyclone_2019": cycloneStyleBinary,
    "obs_cyclone_2020": cycloneStyleBinary,
    "obs_cyclone_2021": cycloneStyleBinary,
    "obs_cyclone_2022": cycloneStyleBinary,
    "obs_cyclone_2010_2022": cycloneStyle,
    "loss_cyclone_bsln": cycloneStyle,
    "loss_cyclone_2050": cycloneStyle,
    "obs_cyclone_previous_year": cycloneStyle,
    "obs_cyclone_previous_5year": cycloneStyle,
    "obs_flood_2012": floodStyleBinary,
    "obs_flood_2013": floodStyleBinary,
    "obs_flood_2014": floodStyleBinary,
    "obs_flood_2015": floodStyleBinary,
    "obs_flood_2016": floodStyleBinary,
    "obs_flood_2017": floodStyleBinary,
    "obs_flood_2018": floodStyleBinary,
    "obs_flood_2019": floodStyleBinary,
    "obs_flood_2020": floodStyleBinary,
    "obs_flood_2021": floodStyleBinary,
    "obs_flood_2022": floodStyleBinary,
    "obs_flood_2012_2022": floodStyle,
    "obs_flood_previous_year": floodStyle,
    "obs_flood_previous_5year": floodStyle,
    "loss_flood_bsln": floodStyle,
    "loss_flood_2050": floodStyle,
    "loss_wildfire_bsln": wildfireStyle,
    "loss_wildfire_2050": wildfireStyle,
    "B19049_001": householdStyle,
    "B23025_c_2": financialStyle,
    "B25004_cal": demoStyle,
    "norm_loan": financialStyle,
    "loss_fraction_bsln": modeledStyle,
    "total_loss_in_USD_K_bsln": modeledStyle,
    "loss_fraction_2050": modeledStyle,
    "total_loss_in_USD_K_2050": modeledStyle,
    "total_loss_delta_fraction": modeledStyle,
    "insurance_nonrenewal_rate": insuranceStyleRed,
    "insurance_premium_category": insuranceStyleOrange,
    "vacancy_rate_prediction_x": householdStyle,
  };

  const user = useContext(authContext);
  useEffect(() => {
    if (selectedProject) {
      user && user.getIdTokenResult()
      .then((token) => {
          if (!!token.claims.api_key) {
              fetch(`${ce_default_url}/api/portfolios`, {
                  method: 'GET',
                  headers: {
                      'X-SustGlobal-APIKey': token.claims.api_key,
                      'X-SustGlobal-Project': selectedProject,
                  }
              })
                  .then(r => r.json())
                  .then(r => {
                    if (!r.errors) {
                      let ports = []
                      r.forEach((p) => {
                        ports.push(p.portfolio_name)
                      })
                      setPortfolios(ports)
                      if (ports.length !== 0) {
                        setSelectedPortfolio(ports[0])
                      }
                    }
                  })
          } else {
              console.error('api key not available for user')
          }
      })
      .catch((error) => {
          console.error(`[APPS_LIST] Error while fetching portfolio data: ${error}`)
      });
    }
  }, [user, selectedProject]);


  useEffect(() => {
    if (selectedBoundary === "Admin 2") {
      setAdminLevel(2);
    } else {
      setAdminLevel(3);
    }
  }, [selectedBoundary]);

  useEffect(() => {
    const importAll = (r, level) => {
      return r.keys().map((k) => {
        return { "name": k.split("/")[1], "level": level };
      });
    };
    const filesAdmin2 = importAll(require.context('../../data/geojson/compose_polys/admin2', false, /\.geojson$/), 2);
    const filesAdmin3 = importAll(require.context('../../data/geojson/compose_polys/admin3', false, /\.geojson$/), 3);
    setComposePolys([filesAdmin2, filesAdmin3]);
  }, []);

  const [parentViewState, setParentViewState] = useState({
    latitude: 40.245,
    longitude: -105.79,
    zoom: 3.5,
  });

  const setMainMap = (value) => {
    setShowMainMap(false);
    setLayerFunction(value);
    console.log(value);
  }

  const composeMainMap = () => {
    setShowMainMap(true);
  }

  const setMap1 = (value) => {
    const selectedOption = availableLayerOptions.find(option => option.propertyName === value.value);
    setInputLayer1(selectedOption);

  };

  const setMap2 = (value) => {
    const selectedOption = availableLayerOptions.find(option => option.propertyName === value.value);
    setInputLayer2(selectedOption);
  };

  const setMap3 = (value) => {
    const selectedOption = availableLayerOptions.find(option => option.propertyName === value.value);
    setInputLayer3(selectedOption);
  };

  // jq '[.features[].properties.GDP_in_K | select(. != null) | tonumber] | max' sample_data_county_composition_example.geojson
  const layerOptions = opts.layerOptions;
  const [availableLayerOptions, setAvailableLayerOptions] = useState(layerOptions);

  const functionOptions = opts.functionOptions;

  const searchBarStyle = {
    container: (baseStyles, state) => ({
      ...baseStyles,
      width: '300px',
    }),
    option: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: state.isSelected ? 'white' : 'black',
      backgroundColor: state.isFocused ? 'gray' : 'black',
      color: 'white',
    }),
    valueContainer: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: 'black',
      borderRadius: '4px',
    }),
    singleValue: (baseStyles, state) => ({
      ...baseStyles,
      color: 'lightgray',
    }),
    noOptionsMessage: (baseStyles, state) => ({
      ...baseStyles,
      color: 'lightgray',
      backgroundColor: 'black',
    }),
    placeholder: (baseStyles, state) => ({
      ...baseStyles,
      color: 'lightgray',
    }),
    dropdownIndicator: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: 'black',
      color: 'white',
      borderRadius: '4px',
    }),
    menuList: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: 'black',
      color: 'white',
      height: '200px',
    }),
    input: (baseStyles, state) => ({
      ...baseStyles,
      color: 'lightgray',
    }),
    control:(baseStyles, state) => ({
      ...baseStyles,
      borderColor: state.isFocused ? 'gray' : 'white',
      borderColor: state.isSelected ? 'gray' : 'white',
      borderRadius: '4px',
    }),
  }

  return (
    <div>
      <div id="main-map-container">
        <ComposeLayerSelector
          selectedProject={selectedProject}
          selectedPortfolio={selectedPortfolio}
          setSelectedPortfolio={setSelectedPortfolio}
          portfolios={portfolios}
          setSelectedBoundary={setSelectedBoundary}
          functionOptions={functionOptions}
          setFunctionLayer={setMainMap}
          composeMainMap={composeMainMap}
        />
        <MainComposeMap
          selectedProject={selectedProject}
          selectedPortfolio={selectedPortfolio}
          selectedFunction={selectedFunction}
          composePolys={composePolys}
          adminLevel={adminLevel}
          adminStyling={adminStyling}
          sourceId={"mainmap"}
          setParentViewState={setParentViewState}
          showMainMap={showMainMap}
        />
      </div>
      <div id="submap-container">
        {/* Map 1 */}
        <div className="submap">
          <div className="input-selector">
            <Select
              styles={searchBarStyle}
              options={availableLayerOptions.map((item) => {
                return {
                  "value": item.propertyName,
                  "label": item.displayName,
                }
              })}
              onChange={setMap1}
              placeholder="Search Inputs..."
            />
          </div>
          <SubMap
            parentViewState={parentViewState}
            selectedLayer={inputLayer1}
            sourceId={"submap1"}
            composePolys={composePolys}
            adminLevel={adminLevel}
            adminStyling={adminStyling}
          />
        </div>
        {/* Map 2 */}
        <div className="submap">
          <div className="input-selector">
            <Select  
              styles={searchBarStyle}
              options={availableLayerOptions.map((item) => {
                return {
                  "value": item.propertyName,
                  "label": item.displayName,
                }
              })}
              onChange={setMap2}
              placeholder="Search Inputs..."
            />
          </div>
          <SubMap
            parentViewState={parentViewState}
            selectedLayer={inputLayer2}
            sourceId={"submap2"}
            composePolys={composePolys}
            adminLevel={adminLevel}
            adminStyling={adminStyling}
          />
        </div>
        {/* Map 3 */}
        <div className="submap">
          <div className="input-selector">
            <Select
              styles={searchBarStyle}
              options={availableLayerOptions.map((item) => {
                return {
                  "value": item.propertyName,
                  "label": item.displayName,
                }
              })}
              onChange={setMap3}
              placeholder="Search Inputs..."
            />
          </div>
          <SubMap
            parentViewState={parentViewState}
            selectedLayer={inputLayer3}
            sourceId={"submap3"}
            composePolys={composePolys}
            adminLevel={adminLevel}
            adminStyling={adminStyling}
          />
        </div>
      </div>
    </div>
  );
};

export default Compose;
